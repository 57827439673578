import React, { useState, useEffect, useContext } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import dayjs from 'dayjs';
import dayjsPluginUTC from 'dayjs-plugin-utc';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import { Context } from 'store/createContext';
import MEDIA from 'helpers/mediaTemplates';
import Layout from 'components/layout';
import Box from 'components/box';
import WorksheetDownload from 'components/worksheet-download';
import Title from 'components/title';
import Banner from 'components/banner';
import Video from 'components/video';
import Ad from 'components/ad';
import GradientLink from 'components/gradient-link';

import { almostBlack, lightGrey, brNorm, rhythm } from 'constants/theme';

// const Button = ({ children, onClick }) => {
//   return (
//     <button className="add-to-calendar-button" onClick={onClick}>
//       {children}
//     </button>
//   );
// };

const Dropdown = ({ children }) => {
  return <div className="add-to-calendar-dropdown">{children}</div>;
};

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const ResiReplayInfo = styled.div`
  padding: ${rhythm};
  border-radius: 0 0 ${brNorm} ${brNorm};
  background-color: ${almostBlack};
  color: ${lightGrey};
  text-align: center;
  line-height: 1.4;

  a {
    color: white;
    text-decoration: underline;
  }

  strong {
    color: white;
  }
`;

const StyledResiContainer = styled.div`
  width: 100%;
  margin: 4rem 0 6rem;
  padding: 0 4rem;

  .resi--container {
    width: 100%;
    position: relative;
    padding-bottom: 56.25%;
    border-radius: ${brNorm} ${brNorm} 0 0;
    overflow: hidden;
  }

  iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

const StyledAdsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 100%);
  grid-template-rows: auto;
  margin-top: 1.25rem;
  margin: 1.25rem 2rem 0 2rem;

  ${MEDIA.DESKTOP`
    grid-template-columns: 100%;
  `};

  ${MEDIA.TABLET`
    margin: 2rem 0rem;
  `};
`;

const Index = ({ data }) => {
  //
  // const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);
  dayjs.extend(dayjsPluginUTC);
  const { events } = data.allEventsJson.nodes[0];

  // Component State
  const [theEvent, setTheEvent] = useState(events[0]);
  const [toggleOn, setToggleOn] = useState(0);
  const store = useContext(Context);
  const { eventTiming, setGlobalEventTiming } = store;

  useEffect(() => {
    (async function init() {
      await checkFeatureToggles();
      // getRsvp();
      websocketConnect();
      // handleCookies();
      loadZendesk();
    })();
  }, []);

  useEffect(() => {
    checkEventStatus(toggleOn);
    // check for event status every minute
    const interval = setInterval(() => {
      checkEventStatus(toggleOn);
    }, 30000);
    return () => clearInterval(interval);
  }, [toggleOn]);

  /**
   *
   * Websocket stuff
   *
   */
  const websocketConnect = () => {
    const heartbeat = ws => {
      ws.send('__beat__');
    };

    let ws;
    const connect = () => {
      let interval;
      ws = new WebSocket('wss://stag.mastery.businessmadesimple.com/ws');

      // Setup our heartbeat to keep connection open
      ws.onopen = () => {
        // console.log('Socket connected.');
        interval = setInterval(() => {
          heartbeat(ws);
        }, 30000);
      };

      // If heartbeat, ignore
      // Otherwise, update our store
      ws.onmessage = msg => {
        if (msg.data === '__alive__') return;
        const { data, event } = JSON.parse(msg.data);

        if (event.includes('ToggleFeatureEvent')) {
          const toggle = data.toggles.find(t => t.feature === 'force_hoam');
          setToggleOn(toggle.on);
        }
      };

      // Clear our heartbeat if websocket closes unexpectedly
      ws.onclose = () => {
        ws = null;
        clearInterval(interval);
        // console.log('Socket connection has closed.');
      };

      ws.onerror = () => {
        // console.log('Socket error: ', { error });
      };
    };

    const reconnect = async () => {
      try {
        await connect();
      } catch (error) {
        // console.log('Error reconnecting to websocket: Error ', { error });
      }
    };

    // Initial socket connection
    try {
      connect();
    } catch (error) {
      // console.log('Socket error connecting.');
    }

    // Reconnect to socket if we lose connection
    setInterval(() => {
      if (!ws) {
        reconnect();
      }
    }, 5000);
  };
  /************************************************ */

  const loadZendesk = () => {
    let zdscript = document.createElement('script');
    zdscript.setAttribute('id', 'ze-snippet');
    zdscript.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.GATSBY_ZENDESK_KEY}`;
    document.getElementsByTagName('body')[0].appendChild(zdscript);

    if (window) {
      var waitForZopim = setInterval(function() {
        if (
          window.$zopim === undefined ||
          window.$zopim.livechat === undefined
        ) {
          return;
        }
        window.$zopim.livechat.badge.hide();
        clearInterval(waitForZopim);
      }, 100);
    }
  };

  const checkFeatureToggles = () => {
    return axios
      .get(process.env.GATSBY_MASTERY_API)
      .then(function(response) {
        // handle success
        if (response.data) {
          const toggle = response.data.find(f => f.feature === 'force_hoam');
          setToggleOn(toggle.on);
        }
      })
      .catch(function() {
        // handle error
        // console.log(error);
      });
  };

  const checkEventStatus = async FEATURE_TOGGLE => {
    // Get event to display
    const now = dayjs.utc();

    // const toggleOn = await checkFeatureToggles();

    // if right now is within an hour of any event's start time
    const currentlyHappening = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const end = dayjs.utc(event.end_date_utc);
      const eventLength = start.diff(end, 'minute');
      const diff = start.diff(now, 'minute');
      return diff <= 15 && diff >= eventLength - 14;
    });

    // if right now is within 1  hours of one of the event's end time
    const recentlyEndedEvents = events.filter(event => {
      const end = dayjs.utc(event.end_date_utc);
      const diff = end.diff(now, 'minute');
      return diff < -14 && diff > -60;
    });

    // any events in the future
    const upcomingEvents = events.filter(event => {
      const start = dayjs.utc(event.start_date_utc);
      const diff = start.diff(now, 'minute');
      return diff > 0;
    });

    if (FEATURE_TOGGLE) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
        setTheEvent(upcomingEvents[0]);
      }
      return;
    }

    if (
      upcomingEvents.length &&
      !currentlyHappening.length &&
      !recentlyEndedEvents.length
    ) {
      if (eventTiming !== 'upcoming') {
        setGlobalEventTiming('upcoming');
        setTheEvent(upcomingEvents[0]);
      }
    }

    if (currentlyHappening.length) {
      if (eventTiming !== 'currently') {
        setGlobalEventTiming('currently');
        setTheEvent(currentlyHappening[0]);
      }
    }

    if (recentlyEndedEvents.length) {
      if (eventTiming !== 'over') {
        setGlobalEventTiming('over');
        setTheEvent(recentlyEndedEvents[0]);
      }
    }
  };

  const StartDateInCentralTime = dayjs(theEvent.start_date_central);
  // const EndDateInCentralTime = dayjs(theEvent.end_date_central);

  const AMorPM = date => {
    let amOrPm = null;
    try {
      date ? (amOrPm = date.format('a') === 'am' ? 'AM' : 'PM') : null;
    } catch (err) {
      //
    }
    return amOrPm;
  };

  // Choosing the ad
  const { ads } = data.allAdsJson.nodes[0];

  // // TODO get more ads and add logic
  // const ad = ads[0];

  // // Calendar Event
  // const calendarEvent = event => {
  //   // let rsvp = store ? store.rsvp : '';
  //   let eventLength = event.description.indexOf('day 2') > -1 ? 0 : 1;
  //   return {
  //     description: event.description,
  //     duration: 6.5,
  //     endDatetime: dayjs(theEvent.end_date_central)
  //       .add(eventLength, 'day')
  //       .format('YYYYMMDDTHHmmss'),
  //     location: 'https://livestream.heroonamission.com/', // TODO change me
  //     startDatetime: dayjs(theEvent.start_date_central).format(
  //       'YYYYMMDDTHHmmss'
  //     ),
  //     title: 'Hero on a Mission LIVE',
  //     timezone: 'America/Chicago',
  //   };
  // };

  return (
    <Layout>
      <Box>
        <Title as="h2" size="large">
          {data.homeJson.content.childMarkdownRemark.rawMarkdownBody}
        </Title>

        <FlexContainer>
          <WorksheetDownload href={theEvent.daily_planner_download}>
            <Img
              fluid={data.imageSharp ? data.imageSharp.fluid : {}}
              alt={'daily planner download'}
            />
            Daily Planner Worksheet
          </WorksheetDownload>
        </FlexContainer>

        {eventTiming === 'currently' ? (
          <>
            <StyledResiContainer>
              <div className="resi--container">
                <iframe
                  title="StoryBrand Marketing Workshop Livestream"
                  allow="autoplay; fullscreen"
                  allowFullScreen={true}
                  src={`https://control.resi.io/webplayer/video.html?id=${theEvent.embed_code}&iframe=1`}
                  style={{ width: '100%' }}
                ></iframe>
              </div>
              <ResiReplayInfo className="resi--replay-info">
                You will have access to the replay of the livestream (not
                coaching breakout sessions) for two full weeks afterward. You
                will receive an email to the address you used for registration.
                Your email will arrive the afternoon of{' '}
                {StartDateInCentralTime.add(
                  theEvent.description.indexOf('day 2') > -1 ? '2' : '3',
                  'day'
                ).format('dddd MMMM D')}
                . Have questions about the stream? Get answers{' '}
                <a
                  href={
                    'https://businessmadesimple.com/hero-on-a-mission-live-faqs/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>HERE.</strong>
                </a>
              </ResiReplayInfo>
            </StyledResiContainer>
          </>
        ) : (
          <Banner>
            <Video
              src={
                'https://player.vimeo.com/external/655469438.hd.mp4?s=d183268ef4946759a3b773170add8dbf02be7fca&profile_id=175'
              }
            >
              <div className="banner-content">
                <h3 className="banner-content__title">
                  {data.bannerJson.title}
                </h3>
                {eventTiming === 'upcoming' && (
                  <p className="banner-content__about">
                    Stream will begin on{' '}
                    {StartDateInCentralTime.format('MMMM D')} at{' '}
                    {StartDateInCentralTime.format('h:mm')}
                    {AMorPM(StartDateInCentralTime)} CT
                  </p>
                )}
                {eventTiming === 'upcoming' ? null : (
                  <>
                    <div className="banner-content__about">
                      This Event is Over
                    </div>
                  </>
                )}
                {/* {eventTiming === 'upcoming' ? (
                  <AddToCalendarDropdown
                    className="add-to-calendar"
                    buttonText="Add to my calendar"
                    event={calendarEvent(theEvent)}
                    items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                  />
                ) : null} */}
                <GradientLink
                  className="cta get-the-planner"
                  href={'https://app.heroonamission.com/register'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Get the online planner
                </GradientLink>
              </div>
              <ResiReplayInfo className="resi--replay-info">
                You will have access to the replay of the livestream for two
                full weeks afterward. You will receive an email to the address
                you used for registration. Your email will arrive the afternoon
                of{' '}
                {StartDateInCentralTime.add('4', 'day').format('dddd, MMMM D')}.
                Have questions about the stream? Get answers{' '}
                <a
                  href={
                    'https://businessmadesimple.com/hero-on-a-mission-live-faqs/'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>HERE.</strong>
                </a>
              </ResiReplayInfo>
            </Video>
          </Banner>
        )}

        <StyledAdsContainer>
          {ads.map(ad => (
            <Ad ad={ad} key={ad.title} />
          ))}
        </StyledAdsContainer>

        {/* <Modal>
        <video
          src="https://i.imgur.com/gzFqNSW.mp4"
          playsInline
          loop
          autoPlay
          muted
        />
      </Modal> */}
      </Box>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
    bannerJson {
      title
      image {
        alt
        source {
          childImageSharp {
            fluid(maxHeight: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allEventsJson {
      nodes {
        events {
          additional
          cta
          description
          embed_code
          start_date_utc
          end_date_utc
          start_date_central
          end_date_central
          id
          name
          daily_planner_download
          banner_image {
            alt
            source {
              childImageSharp {
                fluid(maxHeight: 900, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        id
      }
    }
    allAdsJson {
      nodes {
        ads {
          copy
          cta
          cta_link
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
        }
      }
    }
    imageSharp(original: { src: { regex: "/Worksheet/" } }) {
      gatsbyImageData
      fluid(maxWidth: 30) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;
