import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { darkBlue } from 'constants/theme';

const VideoHero = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border-radius: 3rem;
  overflow: hidden;
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.75;
  background-color: ${darkBlue};
`;

const Children = styled.div`
  z-index: 1;
`;

const _Video = ({ src, children }) => (
  <VideoHero className="video-hero">
    <VideoContainer>
      <Overlay></Overlay>
      <Video autoPlay muted loop>
        <source src={src} type="video/mp4" />
      </Video>
    </VideoContainer>
    <Children>{children}</Children>
  </VideoHero>
);

_Video.propTypes = {
  src: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default _Video;
